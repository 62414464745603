













































































































































































































































import { Vue, Component } from "vue-property-decorator";

import { Input, Button, Table, TableColumn } from "element-ui";
import pagination from "@/components/UIComponents/Pagination.vue";

import {
  MetaModule as metaStore,
  VehicleModule as vehicleStore,
  CommonStore as commonstore,
} from "@/store/modules";

import { AdminRouter } from "@/utils/routePathContsant";
import {
  VehicleListSearchResult,
  ListofVehicles,
} from "@/store/models/registration/registration";
import listTable from "@/views/registrations/vehicle/listTable.vue";
@Component({
  components: {
    Input,
    Button,
    Table,
    TableColumn,
    pagination,
    listTable,
  },
})
export default class VehicleClass extends Vue {
  palikas: any[] = [];
  vehiclePath = AdminRouter.VehicleRegister;
  vehicleDetailPath = AdminRouter.VehicleDetails;
  qVehicle: any[] = [];
  vehicleList: any[] = [];
  districtName: any = [];
  multipleSelection: any = [];
  listToBeSubmitted: number[] = [];
  vehicleListByApproval: any[] = [];
  filter = {
    name: "",
    district: "",
    palika: "",
  };
  status: VehicleListSearchResult = {
    profile__approval_status: "",
    page: null,
    page_size: null,
    submitted: true,
  };
  lts: ListofVehicles = {
    vehicles: [],
  };

  get AdminRouter() {
    return AdminRouter;
  }

  get routePath() {
    return AdminRouter;
  }
  get districts() {
    return metaStore.districtL;
  }

  get vehicleCount() {
    return vehicleStore.vehicleCount;
  }

  async refreshlist() {
    await vehicleStore.loadAllByStatus(this.status);
    this.vehicleListByApproval = vehicleStore.vehicleList;
    await vehicleStore.getVehicleCount();
  }

  async created() {
    metaStore.loadDis();
    vehicleStore.getVehicleCount();
    let vm = this;
    const id = vm.$route.query["vehicle_owner"];
    if (id) {
      await vehicleStore.loadAllVehicleByOwner(id);
    } else {
      this.status.page = 1;
      this.status.profile__approval_status = "Not Submitted";
      await vehicleStore.loadAllByStatus(this.status);
    }

    this.vehicleListByApproval = vehicleStore.vehicleList;
  }

  edit(id: any) {
    this.$router.push(AdminRouter.VehicleRegister + "?id=" + id);
  }
  async filterPalika() {
    this.filter.palika = "";
    this.palikas = await metaStore.loadMuncipality(
      parseInt(this.filter.district)
    );
  }
  filterVehicle() {
    var filterData = vehicleStore.vehicleList;

    if (this.filter.name !== "") {
      this.filter.name = this.filter.name.toLowerCase();
      filterData = filterData.filter((x) =>
        x.vehicleNumber.toLowerCase().includes(this.filter.name)
      );
    }

    this.vehicleListByApproval = filterData;
  }
  clearFilter() {
    this.filter = {
      name: "",
      district: "",
      palika: "",
    };
    this.vehicleListByApproval = vehicleStore.vehicleList;
  }
  async submitForApprove() {
    let b = this.multipleSelection;
    for (var i = 0; i < b.length; i++) {
      let p = b[i].id;
      this.listToBeSubmitted.push(p);
      this.lts.vehicles.push(p);
    }

    await vehicleStore.submitVehicles(this.lts);
    this.$snotify.success("Submit Successfully");
    (this.$refs["multipleTable"] as any).clearSelection();
    commonstore.hideLoading();
    this.status.page = 1;
    this.status.submitted = true;
    await vehicleStore.loadAllByStatus(this.status);
    this.vehicleListByApproval = vehicleStore.vehicleList;
    this.$router.push(AdminRouter.VehilceList);
    await vehicleStore.getVehicleCount();
  }
  handleSelectionChange(val: any) {
    this.multipleSelection = val;
  }
  clearSelect() {
    (this.$refs["multipleTable"] as any).clearSelection();
  }
  submit(e: any) {
    vehicleStore.createVehicle();
  }

  async handleClick(tab: any, event: any) {
    this.status.profile__approval_status = tab._props.label;
    this.status.page = 1;
    if (this.status.profile__approval_status === "Not Submitted") {
      this.status.submitted = false;
    } else {
      this.status.submitted = true;
    }

    await vehicleStore.loadAllByStatus(this.status);
    this.vehicleListByApproval = vehicleStore.vehicleList;
    await vehicleStore.getVehicleCount();
  }

  async onDeleteVehicle(obj: any) {
    this.$confirm(
      `Are you sure you want delete this vehicle ${obj.vehicleNumber}. Continue?`,
      "Warning",
      {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
        center: true,
      }
    )
      .then(() => {
        vehicleStore.deleteVehicle(obj.id);
        window.location.reload();
        this.$message({
          type: "info",
          message: "Deleted Successfully",
        });
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "Delete canceled",
        });
      });
  }
}



































































import { Vue, Component, Prop } from "vue-property-decorator";
import { AdminRouter } from "@/utils/routePathContsant";
import pagination from "@/components/UIComponents/Pagination.vue";
import { Button } from "element-ui";
import { VehicleModule as vehicleStore } from "@/store/modules";
import helpers from "@/utils/helpers";
@Component({
  components: {
    pagination,
    Button,
  },
})
export default class VehicleClass extends Vue {
  @Prop()
  data!: any;
  @Prop({ default: false })
  editBtn!: any;

  vehiclePath = AdminRouter.VehicleRegister;
  vehicleDetailPath = AdminRouter.VehicleDetails;
  qVehicle: any[] = [];

  momentDate(date: any) {
    return helpers.toMomentFormat(date);
  }

  reapplyVehicle(id: any) {
    let vm = this;

    this.$confirm(`Are you sure you want to Re-Apply?`)
      .then((_) => {
        vehicleStore.reapplyVehicle(id);
        this.$snotify.success("Reapplied Successfully");
        this.$router.push(AdminRouter.VehilceList);
        this.$emit("refreshlist");
      })
      .catch((_) => {});
    vehicleStore.getVehicleCount();
  }
}
